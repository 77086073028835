import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async create(keygen, planId) {
    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/create`, {
        planId: planId,
        sponsor: VUE_APP_SPONSOR,
        keygen: keygen,
      });

      return shieldRes.data;

    } catch (error) {
      return 'failed'
    }
  }

  static async getPlans(sponsor) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/car?sponsor=${sponsor}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async newCar(carId, propietarioData, keygen, planId, path, motorNull, newNumberMotor) {

    try {
      var params = {
        carId: carId,
        propietarioData: propietarioData,
        keygen: keygen,
        planId: planId,
        sponsor: VUE_APP_SPONSOR,
        path: path,
        motorNull: motorNull,
        newNumberMotor: newNumberMotor,
      }

      const vaultRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/createCar`,
        {
          params
        }
      );

      return vaultRes.data.data;

    } catch (error) {
      console.log(error)
      return 'failed'
    }
  }

  static async getCars(keygen) {
    const vaultRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/getCars`,
      {
        keygen: keygen,
      }
    );

    return vaultRes.data;
  }

  static async getToken(token) {
    const CarsTokenRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/car/get/${token}`
    );

    return CarsTokenRes.data.data;
  }

  static async getKeygen(keygen) {
    const CarsTokenRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/car/getKeygen/${keygen}`
    );

    return CarsTokenRes.data.data;
  }

  static async deleteCar(id) {
    const vaultRes = await axios.delete(`${VUE_APP_SHIELD_URL}/car/deleteCar/${id}`
    );
    return vaultRes.data.data;
  }

  static async consultaCar(patente, rut) {
    try {

      const vaultRes = await axios.get(`${VUE_APP_SHIELD_URL}/car/getPatente/${patente}/${rut}`
      );
      return vaultRes.data;

    } catch (error) {
      console.log(error)
      return 'failed'
    }
  }

  static async consultasoapAprobado(patente) {
    const vaultRes = await axios.get(`${VUE_APP_SHIELD_URL}/car/consultasoapAprobado/${patente}`
    );
    return vaultRes.data;
  }

  static async pagoCencosud(keygen, sponsor) {

    const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/cencosud`, {
      sponsor: sponsor,
      keygen: keygen,
    });

    return shieldRes.data.data;
  }

  static async updatePagador(keygen, propietarioData) {

    const vaultRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/updatePagador`,
      {
        keygen: keygen,
        propietario: propietarioData.propietario,
        rutPropietario: propietarioData.rutPropietario
      },
    );
    return vaultRes.data.data;
  }

  static async createSoapConsulta(propietarioData, carData) {

    try {

      const vaultRes = await axios.post(`${VUE_APP_SHIELD_URL}/car/createSoapConsulta`,
        {
          propietarioData: propietarioData,
          carData: carData,
          sponsor: VUE_APP_SPONSOR,
        },
      );
      return vaultRes.data;

    } catch (error) {
      console.log(error)
      return 'failed'
    }

  }

}
