<template>
  <div>

    <div class=" text-center footer">
      <br>

      <label class="cobertura">Cobertura entregada por
      </label>

      <br>

      <div class="text-center">

        <v-img contain max-height="160px" :src="LogoSB" max-width="245" style=" margin: auto; " />

      </div>

      <label class="detalle">Más de 40 años en la industria del seguro, ofreciendo
        productos desarrollados
      </label>

      <br>

      <label class="detalle">para satisfacer las necesidades locales e internacionales.
      </label>

    </div>

  </div>
</template>

<script>
import LogoSB from "@/assets/images/logo2.jpg";

export default {
  name: "Footer",
  mounted() {
  },
  data: () => ({
    LogoSB,
    path: null,
    puntos: null,
    screen: null,
    banner: null,
    texto: null,
  }),
  mounted() {
    this.screen = window.screen.width
    if (this.screen > 800) {
      this.banner = 'text-center banner'
      this.texto = 'texto'
    } else {
      this.banner = 'text-center banner2'
      this.texto = 'texto2'
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.cobertura {
  text-align: center;

  letter-spacing: 0.3px;
  color: #3333CC;
  text-transform: uppercase;
  opacity: 1;
}

.detalle {
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #4D4D4D;
  opacity: 1;
}

.footer {
  height: 222px;
  background: rgba(51, 51, 204, 0.062) 0% 0% no-repeat padding-box;
}

</style>
