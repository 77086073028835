<template>
  <v-app>

    <v-app-bar v-if="screen > 800" :class="navVar" style="height: 200px;">

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-app-bar v-else :class="navVar" style="height: 46.5vw ;">

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import MovilClientes from "@/assets/images/MovilClientes.jpg";
import MovilGeneral from "@/assets/images/MovilGeneral.jpg";
import MovilColaborador from "@/assets/images/MovilColaborador.jpg";

export default {
  components: {
    Footer,
  },
  data: () => ({
    MovilClientes,
    MovilGeneral,
    MovilColaborador,
    screen: window.screen.width,
    navVar: '',
    pathname: window.location.pathname,
    camapana: '',

  }),
  mounted() {
    if (this.screen > 800) {
      this.navVar = 'navVarClientes'
    } else if (this.screen < 800) {
      this.navVar = 'navVarClientesMovil'
    }
  }
};
</script>

<style>
.navVarClientes {
  background-image: url("~@/assets/images/SoapSeguroFacil.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarClientesMovil {
  background-image: url("~@/assets/images/SoapSeguroFacil_mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

}

@font-face {
  font-family: "Scotia_Bd";
  src: local("Scotia_Bd"),
    url(/fonts/Scotia_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_BdIt";
  src: local("Scotia_BdIt"),
    url(/fonts/Scotia_BdIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Headline";
  src: local("Scotia_Headline"),
    url(/fonts/Scotia_Headline.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_It";
  src: local("Scotia_It"),
    url(/fonts/Scotia_It.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Legal";
  src: local("Scotia_Legal"),
    url(/fonts/Scotia_Legal.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Lt";
  src: local("Scotia_Lt"),
    url(/fonts/Scotia_Lt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_LtIt";
  src: local("Scotia_LtIt"),
    url(/fonts/Scotia_LtIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Rg";
  src: local("Scotia_Rg"),
    url(/fonts/Scotia_Rg.otf) format("truetype");
}
</style>