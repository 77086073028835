<template>

  <div>

    <br>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" width="80%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#3333CC" dark v-bind="attrs" v-on="on" :class="consulta">
              <v-icon> mdi-shopping </v-icon>
            </v-btn>
            <v-btn v-if="(e1 == 0)" dark style="margin-left: 1%; background-color: #FFB600;" @click="Consultar()">
              Consulta tu SOAP
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Estás contratando un SOAP para los siguientes vehículos:</span>
            </v-card-title>
            <v-card-text>
              <template>
                <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
                  :items-per-page="5" sort-by="price" class="elevation-1">
                  <template v-slot:top>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Estás seguro de eliminar este producto?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmar</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>

                  <template v-slot:item.price="{ item }">
                    <label style="margin:auto">{{ formatPrice(item.price) }}</label>
                  </template>

                </v-data-table>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </div>

</template>
  
<script>
import ShieldService from "../services/shield.service";

export default {
  name: "InitForm",
  props: ['patentes', 'e1'],
  components: {
  },
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    screen: window.screen.width,
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    consulta: '',
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', },
      { text: 'Correo', value: 'personEmail', align: 'center', },
      { text: 'Valor SOAP', value: 'price', align: 'center', },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center', },
    ],
  }),
  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await ShieldService.deleteCar(this.patentes[this.editedIndex]._id).then((response) => {
        if (response == 'Delete car') {
          this.patentes.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("reload");
        }
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    Consultar() {
      window.location.pathname = '/consulta'
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
  },
  async mounted() {
    if (this.screen > 800) {
      this.consulta = 'consultar'
    } else if (this.screen <= 800) {
      this.consulta = 'consultar2'
    }
  },
};
</script>
  
<style lang="css" scoped>
.consultar {
  margin-left: 50%;
}

.consultar2 {
  margin-left: 20%;
}
</style>